module.exports={
    message:{
        homeNavi: '首页',
        aboutNavi: '关于我',
        myTitle: '蜜柑的主页',
        aboutTitle: '使用Vuejs重写了一个版本',
        aboutContent: [
            "你好！",
            "初次见面。感谢您对我这么感兴趣，还点进第二个页面来看自我介绍。稍稍有点害羞(////)。花了半天的时间写好的网站但不知道在这里可以写什么，那就简单地把这里当成扩列资料咯~",
            "一般路过邦邦人。你可以叫我蜜柑，这个词在中文和日文里都写作蜜柑。所以就没有什么交流上的障碍了。意义上呢其实都是ネタ自我喜欢的两个角色：高海千歌、戸山香澄。奇卡是蜜柑大使。香澄嘛，中文谐音香橙，又歪到蜜柑上去了（笑）",
            "这个网站是用Vuejs写的，因为我觉得Vuejs很好用。这个网站的代码是开源的，如果你觉得好看，你可以在Github上找到它，改改源代码就变成你的了。",
            "玩网20年，目前只会写点垃圾代码，不过我会继续努力的。（话说回来我好像也不是计科软工专业，原谅自己了",
            "关于交友：",
            "害怕主动社交，所以我不会找人说话。但是如果你想和我聊聊天，交朋友的话，我会很高兴的。在首页你可以找到我的社交网站账号，直接私信就好，回复很快~",
            "兴趣爱好广泛，编程（前端加一点后端）/游戏（LoveLive!/邦多利/世界计划/中二节奏/炼金工房/弹丸论破）/音乐（华语经典、jpop、摇滚）/ACG/健身/观影（科幻、故事）/文学（中国的偏近现代，外国的偏日系）。没有特别雷的东西，除非当众骂我是傻逼。",
            "（以上内容30%来自Copilot）"
        ],
        fnfTitle: '页面未找到',
        fnfSubtitle: '你来到了没有知识的荒原',
        fnfDescribe1: '很抱歉，您想访问的页面之前可能曾经可用但现在不可用。',
        fnfDescribe2: '你可以选择',
        fnfBackToTop: '返回首页',
        fnfDescribe3: '或者等待我们帮助您找到可能正确的页面：',
        fnfWait: '\n等待重定向……',
        fnfSecond: '秒',
        linksTitle: '友情链接',
        gcmTitle: '拷贝我的各种ID',
        gcmSubtitle: '一键加好友',
    }
}
