module.exports={
    message:{
        homeNavi: '首頁',
        aboutNavi: '關於我',
        myTitle: '蜜柑的主頁',
        aboutTitle: '使用Vuejs重寫了一個版本',
        aboutContent: [
            "你好！",
            "初次見面，感謝您對我這麽感興趣，還點進來第二個頁面來看自我介紹。稍稍有點害羞呢(////)。這個網站花了半天時間做出來，但是我不知道該在這裡寫些什麽。那就把這裡當作附加資料吧~",
            "一般路過邦邦人。你可以叫我蜜柑，這個詞在中文和日文裡都寫作蜜柑。所以就沒有什麼交流上的障礙了。意义上呢其实都是ネタ自我喜欢的两个角色：高海千歌、戸山香澄。奇卡是蜜柑大使。香澄嘛，中文諧音香橙，又歪到蜜柑上去了（笑）",
            "這個網站是用Vuejs寫的，因為我覺得Vuejs很好用。這個網站的代碼是開源的，如果你覺得好看，你可以在Github上找到它，改改原始程式碼就變成你的了。",
            "玩網20年，目前只會寫點垃圾代碼，不過我會繼續努力的。（話說回來我好像也不是計科軟工專業，原諒自己了",
            "關於交友：",
            "害怕主動社交，所以我不會找人說話。但是如果你想和我聊聊天，交朋友的話，我會很高興的。在首頁你可以找到我的社交網站帳號，直接私信就好，回覆很快~",
            "興趣愛好廣泛，程式設計（前端加一點後端）/遊戲（LoveLive!/BanG Dream!/世界計畫/CHUNITHM/煉金工房/槍彈辯駁）/音樂（華語經典、jpop、搖滾）/健身/ACG/觀影（科幻、故事）/文學（中國大陸的偏近現代，外國的偏日系）。沒有特別雷的東西，除非當眾罵我是傻逼。",
            "（以上內容30%來自Copilot）"
        ],
        fnfTitle: '頁面未找到',
        fnfSubtitle: '你來到了沒有知識的荒原',
        fnfDescribe1: '很抱歉，你貌似來到了一個之前可能可用但現在不可用的頁面。',
        fnfDescribe2: '你可以選擇',
        fnfBackToTop: '返回首頁',
        fnfDescribe3: '或者等待我們幫助你找到可能正確的頁面：',
        fnfWait: '\n等待重定向……',
        fnfSecond: '秒',
        linksTitle: '友情鏈接',
        gcmTitle: '我的各種ID',
        gcmSubtitle: '你可以在這裡找到我的游戲ID',
    }
}
