module.exports={
    message:{
        homeNavi: 'Home',
        aboutNavi: 'About me',
        myTitle: 'Hello! I am Mikan.',
        aboutTitle: 'Rewritten with Vuejs',
        aboutContent: [
            "Hello!",
            "Thank you for visiting my site. You came to see my introduction, didn't you? I'm a little embarrassed (////). It took half a day to make this site, but I don't know what to write here. Let's use this as an extension document ~",
            "I'm Mikan, a BanG Dreamer. About my name Mikan, this word looks same in Japanese and Chinese -- Mikan. So there is no communication barrier. The meaning is from two characters I like: Chika Takami and Kasumi Toyama. Chika she is the ambassador of the Nishiura Mikan. Toyama sounds like another Chinese homophone for Mandarin oranges, and the name comes(LOL).",
            "This site, I think Vuejs is easy to use, so I made it with Vuejs. The source code is open source, so if you think it's good, you can find it on Github and change the source code a little bit to make it your own (please follow the open source license).",
            "I've been doing web for 20 years, and now I can only write stupid code, but I'll keep trying. (But I'm not a software engineering major, so I guess it's okay?)",
            "To friends who want to make friends with me:",
            "I'm a little shy, so I don't look for a conversation partner. But if you want to talk and become friends, I'm happy. My SNS account is on the top page. If possible, please PM me directly and I will reply to you as soon as possible ~",
            "I'm not good at English, so I'm sorry if I make a mistake. I hope you can understand me. Thank you for your visit ~",
            "(30% of the content created by GitHub Copilot)"
        ],
        fnfTitle: '404',
        fnfSubtitle: 'Page not found',
        fnfDescribe1: 'Sorry, the page you are looking for is temporarily unavailable on this site. ',
        fnfDescribe2: 'Go back to the ',
        fnfBackToTop: 'homepage',
        fnfDescribe3: 'Or you will be redirected to ',
        fnfWait: ' in ',
        fnfSecond: ' s',
        linksTitle: 'Links',
        gcmTitle: 'My gaming IDs',
        gcmSubtitle: 'You can use these IDs to add me as a friend',
    }
}
