module.exports={
    message:{
        homeNavi: 'Home',
        aboutNavi: 'About me',
        myTitle: 'こんにちは！ミカンです。',
        aboutTitle: 'Vuejsで再作成した',
        aboutContent: [
            "はじめまして！",
            "ミカンです。このサイトを見てくれてありがとうございます。ウチの自己紹介を見に来てくれたんですね。ちょっと恥ずかしいです(////)。半日かけて作ったサイトなのに、ここに何を書けばいいのかわからない。じゃここは拡張資料として使いましょう～",
            "バンドリマー。ミカンと呼んでください。この言葉は日本語でも中国語でも書くとは同じーー蜜柑。だからコミュニケーション上の障害はありません。意味は、私が好きな二つのキャラクター：高海千歌、戸山香澄のネタからです。奇卡は西浦ミカン大使。香澄は、中国語の諧音香橙、そしてミカンに歪んでいます（笑）",
            "このサイトはね、Vuejsは簡単に使うと思いますから、Vuejsで作り直しました。ソースコードはオープンソースなので、もしあなたはいいだと思ったら、Githubで探して、ソースコードをちょっと変更すれば自分のものになれますよ（オープニングソース規約に従ってください）。",
            "20年間Webをやってきて、今はくだらないコードしか書けませんが、これからも頑張ります。 (でも自分はソフト工学専攻でもないようなんで、許されるでしょう？",
            "フレンドが作りたい方へ：",
            "ちょっと陰キャラなので、話し相手を探さないんです。 でも、おしゃべりして友達になりたい方がいるなら、喜んでいます。 私のSNSのアカウントはトップページにありますよ。できれば直接にDMを送って、すぐに返信してあげます〜",
            "趣味は幅広く、プログラミング（フロントエンド、バックエンドも少々）／ゲーム（ラブライブ/バンドリ/プロセカ/チュウニズム/アトリエシリーズ/ダンガンロンパ　など）／音楽（中国古典、jpop、ロック）／フィットネス／アニメ、映画鑑賞／文学（中国は現代文、外国は日本文系）などです。 人前でウザイと言われない限り、特に嫌なこともありません。",
            "（上記の30%はGitHub Copilotより）"
        ],
        fnfTitle: '404',
        fnfSubtitle: 'ページが見つかりません',
        fnfDescribe1: 'あなたが探しているページは見つかりませんでした。',
        fnfDescribe2: '今できることは、以下のいずれかです：',
        fnfBackToTop: 'トップページに戻る',
        fnfDescribe3: 'もしくは、正しいURLに移動するまでお待ちください：',
        fnfWait: '\nあと',
        fnfSecond: '秒',
        linksTitle: 'Links',
        gcmTitle: 'ゲームIDたち',
        gcmSubtitle: 'ゲームのIDを見せてあげるよ',
    }
}
